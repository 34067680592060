<template>
    <span>
        <PopupDialog :dialogOpen="dialog"/>
        <v-skeleton-loader :loading="loadingContent" type="list-item-avatar-two-line" transition="fade-transition">
        <v-hover v-slot:default="{ hover }" style="cursor: pointer;">
        <v-card flat class="pa-3" :color="hover ? 'grey lighten-3' : 'white'" @click="$router.push({name: 'WebAppProfile', params: {id: user.ID_user}})">
            <v-row>
                <v-col cols=4>
                    <ProfilePhoto :src="photo" heightImage="80" />
                </v-col>
                <v-col cols=8>
                    <div>{{ user.name }}</div>
                    <div v-for="(event, index) in user.events" :key="index">
                        <span v-if="event.isActive || !onlyActive">
                            <span class="caption">{{ titles[event.ID_offer] }} </span>
                            <v-icon v-if="!onlyActive" small :class="[event.isActive ? 'green--text' : 'red--text']" v-text="event.isActive ? 'check' : 'close'"></v-icon>
                        </span>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        </v-hover>
        </v-skeleton-loader>
    </span>
</template>

<script>
    import axios from 'axios'
    import ProfilePhoto from '@/components/partials/ProfilePhoto'
    import PopupDialog from '@/components/partials/PopupDialog'
    import loadingMixin from '@/mixins/loadingMixin'
    export default {
        name: "UserEventLink",
        components:  {ProfilePhoto,PopupDialog},
        mixins: [loadingMixin],
        props: {
            onlyActive: {
                type: Boolean,
                default: true
            },
            user: {
                type: Object,
                required: true
            }

            /* Objekt tvaru: 
            {
                name: String, (meno a priezvisko Usera)
                events: [
                    {
                        ID_offer: Integer, (ID Eventu)
                        isActive: Boolean, (status linku daneho Usera na dany Event)
                        worked: String (kolko hodin ma odrobenych na danom evente)
                    }
                ],
                ID_User: Integer (ID Usera)
            } 
            */
        },
        data() {
            return {
                photo: '',
                dialog: false,
                titles: {},
                loadedAmountRequired: 2
            }
        },
        methods: {
            getUserPhoto() {
                axios.post('/auth/user', {'ID_user': this.user.ID_user})
                .then(response => {
                    this.photo = response.data.photo
                    this.load(1)
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    }
                })
            },
            getEventTitles() {
                let ids = this.user.events.map(x => x.ID_offer)
                axios.post('/get_offers_info', {ID_offers: ids})
                .then(
                    response => {
                        response.data.info.forEach(event => {
                            this.titles = {...this.titles, [event.ID_offer]: event.title}
                        })
                        this.load(1)
                    }
                )
                .catch(() => {
                    this.dialog = true
                })
            }
        },
        watch: {
            user: function () {
                this.unload()
                this.getUserPhoto()
                this.getEventTitles()
            }
        },
        mounted() {
            this.unload()
            this.getUserPhoto()
            this.getEventTitles()
        }
    }
</script>

<style lang="scss" scoped>

</style>