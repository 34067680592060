<template>
    <div>
        <v-row>
            <PopupDialog :dialogOpen="dialog"/>
            <v-col cols=12 md=6 align-self="center">
                <div class="headline">{{ $t("requests.title") }}</div>
            </v-col>
            <v-col cols=12 md=4 lg=3 v-if="titleFilter">
                <v-select
                dark
                background-color="rgb(255,157,113)"
                color="white"
                rounded 
                filled 
                dense
                :items="eventsOz"
                item-text="title"
                :label="$t('requests.chooseOffer')"
                v-model="eventChosen"
                return-object
                :no-data-text="$t('requests.noOffers')"
                ></v-select>
            </v-col>
        </v-row>
        <div v-if="eventChosen">
            <div v-if="eventChosen.linkedUsers >= eventChosen.demand">
                {{ $t("requests.offerFull") }}
            </div>            
            <div v-else-if="filteredRequests.length > 0">
                <div>{{ $t("requests.freePlaces") }}: {{ freePlaces }}</div>
                <v-expansion-panels accordion class="mt-5">
                    <v-expansion-panel 
                    :style="decisions[request.ID] === true ? 'background-color: #E8F5E9;' : decisions[request.ID] === false ? 'background-color: #FFEBEE;' : ''" 
                    v-for="(request, index) in filteredRequests[0].requests" :key="index" 
                    class="mx-5">
                        <v-expansion-panel-header class="pa-0 pr-5">
                            <v-row>
                                <v-col cols=auto align-self="center">
                                    <span v-if="decisions[request.ID] === true"><v-icon class="mr-1 green--text ml-5">done</v-icon></span>
                                    <span v-if="decisions[request.ID] === false"><v-icon class="mr-1 red--text ml-5">close</v-icon></span>
                                </v-col>
                                <v-col cols=6 align-self="center">
                                    <span class="subtitle-1">{{request.username}}</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols=auto align-self="center">
                                <v-btn class="text-none" text color="green" @click.native.stop="decideReguest(request.ID, true)">
                                    <v-icon class="mr-1">done</v-icon>
                                    <span>{{ $t("requests.confirm") }}</span>
                                </v-btn>
                                </v-col>
                                <v-col cols=auto align-self="center">
                                <v-btn class="text-none" text color="red" @click.native.stop="decideReguest(request.ID, false)">
                                    <v-icon class="mr-1">close</v-icon>
                                    <span>{{ $t("requests.decline") }}</span></v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="grey--text caption">{{ $t("requests.skills") }}</div>
                            <span v-for="(tag, index) in request.tags" :key="index">
                                <Tag v-if="tag" :name="tag"/>
                            </span>
                            <div class="grey--text caption">{{ $t("requests.description") }}</div>
                            <div :class="['grey--text', 'text--darken-2', $vuetify.breakpoint.smAndDown ? 'body-2' : '']">{{request.desc}}</div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row class="mx-5" v-if="Object.keys(decisions).length > 0">
                    <v-spacer></v-spacer>
                    <v-col cols=auto>
                        <v-btn text class="grey--text text--darken-2 text-none" @click="decisions={}">
                            <v-icon class="primary--text mr-2">cancel</v-icon>
                            <span>{{ $t("cancel") }}</span>
                        </v-btn>
                    </v-col>
                    <v-col cols=auto>
                        <v-btn color="primary" @click="sendDecisions">
                            <span>{{ $t("requests.send") }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                {{ $t("requests.noRequests") }}
            </div>
        </div>
    </div>
</template>

<script>
import PopupDialog from '@/components/partials/PopupDialog'
import Tag from '@/components/partials/Tag'

import axios from 'axios'
    export default {
        components: {PopupDialog, Tag},
        props: {
            requests: {
                type: Array,
                required: true
            },
            eventsOz: {
                type: Array,
                required: true
            },
            titleFilter: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            filteredRequests() {
                let filteredRequests = []
                if (this.titleFilter) {
                    for (let i = 0; i < this.requests.length; i++) {
                        let eventSelect = this.eventChosen
                        let ID_offerSelect = eventSelect.ID_offer
                        if (ID_offerSelect == this.requests[i].ID_offer) {
                            filteredRequests.push(this.requests[i])
                        }
                    }
                }
                else {
                    filteredRequests = this.requests
                }
                return filteredRequests
            },
            freePlaces() {
                let approve = []
                Object.keys(this.decisions).forEach(element => {
                    if (this.decisions[element] === true) {
                        approve.push(parseInt(element))
                    }
                })
                return this.eventChosen.demand - this.eventChosen.linkedUsers - approve.length;
            }
        },
        watch: {
            eventsOz: function() {
                this.eventChosen = this.eventsOz[0]
            }
        },
        data() {
            return {
                eventChosen: this.eventsOz[0],
                decisions: {},
                dialog: false,
                error: ''
            }
        },
        methods: {
            decideReguest: function(ID, decision) {
                if (!decision || this.freePlaces > 0) {
                    if (ID in this.decisions) {
                        this.decisions[ID] = decision
                    }
                    else {
                        this.decisions = { ...this.decisions, [ID]: decision}
                    }
                }
                if (this.freePlaces == 0) {
                    this.filteredRequests[0].requests.forEach(element => {
                        if (!(element.ID in this.decisions)) {
                            this.decisions = { ...this.decisions, [element.ID]: false}
                        }
                    })
                }
            },
            sendDecisions: function() {
                if (Object.keys(this.decisions).length > 0) {
                    let deny = []
                    let approve = []
                    Object.keys(this.decisions).forEach(element => {
                        if (this.decisions[element] === true) {
                            approve.push(parseInt(element))
                        }
                        else if (this.decisions[element] === false) {
                            deny.push(parseInt(element))
                        }
                    });
                    let data_to_post = {
                        deny: deny,
                        approve: approve
                    }
                    axios.post('/auth/decide_requests', data_to_post)
                    .then(() => {
                        this.$emit('decision')
                    })
                    .catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.$router.push({ name: 'logout' })
                        } else {
                            this.dialog = true
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.color_approve {
    background-color: #E8F5E9;
}
.color_deny {
    background-color: #FFEBEE;
}
</style>