<template>
    <div>
        <v-row justify="center">
            <v-col cols=12 md=6 align-self="center">
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="$t('eventTable.search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols=12 md=auto align-self="center">
                <router-link :to="{name: 'WebAppEventCreate'}">
                    <v-btn text class="primary--text plain">
                        <v-icon class="mr-1">add_circle</v-icon>
                        <span class="text-none">{{ $t("eventTable.createOffer") }}</span>
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols=12 md=auto>
                <v-checkbox v-model="$store.state.pastEvents" :label="$t('eventTable.showPastOffers')" hide-details class="ma-0"></v-checkbox>
            </v-col>
        </v-row>
        <v-data-table
                :items="filteredEvents"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-header
                hide-default-footer
                :no-data-text="$t('eventTable.noOffers')"
            >
        <template v-slot:item="{ item }">
                <tr height="60" @click="eventLink(item.ID_offer)" style="cursor: pointer;">
                    <td style="width: 30%; border-bottom: thin solid var(--v-primary-base);"><span :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'headline'">{{ item.title }}</span></td>
                    <td v-if="item.place" style="width: 25%;" :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''"><v-icon class="primary--text mr-2">place</v-icon>{{ item.place }}</td>
                    <td v-else :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''"><v-icon class="primary--text mr-2">near_me</v-icon>{{ findRegionNameByCode(item.region) }}</td>
                    <td :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''"><v-icon class="primary--text mr-2">date_range</v-icon>
                        <span v-if="item.type == 4">{{ $t("offer.longTerm") }}</span>
                        <span v-else-if="item.endDate && item.endDate != item.date">{{formatDate(item.nextEventDate)}} - {{formatDate(item.endDate)}}</span>
                        <span v-else>{{formatDate(item.nextEventDate)}}</span>
                    </td>
                    <td :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''" v-if="item.type != 4"><v-icon class="primary--text mr-2">access_time</v-icon>
                        <span v-if="item.type == 1 || item.type == 2">{{ $t("offer.allDay") }}</span>
                        <span v-else>{{ item.time }} - {{ item.endTime }}</span>
                    </td>
                    <td v-else>
                    </td>
                    <td :class="$vuetify.breakpoint.smAndDown ? 'caption' : ''">{{ $t("offer.joined") }}: {{ item.linkedUsers }}/{{ item.demand }}</td>
                </tr>
        </template>
        </v-data-table>
        <v-pagination v-if="pageCount" v-model="page" :length="pageCount" total-visible=9></v-pagination>
    </div>
</template>

<script>
    import utilitiesMixin from '@/mixins/utilitiesMixin.js'

    export default {
        name: "EventTable",
        mixins: [utilitiesMixin],
        props: {
            events: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                page: 1,
                itemsPerPage: 5,
                search: '',
                eventsTable: []
            }
        },
        computed: {
            pageCount: function() {
                return Math.ceil(this.filteredEvents.length/this.itemsPerPage)
            },
            filteredEvents: function() {
                return this.events.filter(event => this.searchIn(event.title, this.search))
            }
        },
        methods: {
            eventLink(id) {
                this.$router.push({name: 'WebAppEventDetail', params: {id: id}})
            },
            searchIn(str, search) {
                return str.toLowerCase().includes(search.toLowerCase())
            }
        },
    }
</script>

<style lang="scss" scoped>
td {
    border-bottom: thin solid var(--v-primary-base) !important;
}
</style>