<template>
    <v-card class="py-10 px-5 mx-3" flat style="border-radius: 20px">
        <SnackBar :snackbarOpen="snackbar" :text="$t('dashboard.created')" @snackBarClosed="snackbar=false"/>
        <PopupDialog :dialogOpen="dialog"/>
        <span class="headline mt-5">{{ $t("dashboard.offers") }}</span>
        <v-skeleton-loader :loading="loadingContent" type="table" transition="fade-transition">
            <EventTable :events="eventsOrderedByDate"/>
        </v-skeleton-loader>
        <LinkedUsersDisplay :users="linkedUsersData" :eventsOz="eventsOrderedByDate" :titleFilter="true" :isActiveFilter="true"/>
        <RequestsDisplay :requests="pendingRequests" :eventsOz="events.filter(event => {return event.isProtected})" :titleFilter="true" @decision="reloadData()" />
    </v-card>
</template>

<script>
    import axios from 'axios'
    import EventTable from '@/components/dashboard/EventTable'
    import LinkedUsersDisplay from '@/components/dashboard/LinkedUsersDisplay'
    import RequestsDisplay from '@/components/dashboard/RequestsDisplay'
    import PopupDialog from '@/components/partials/PopupDialog'
    import SnackBar from '@/components/partials/SnackBar'
    import loadingMixin from '@/mixins/loadingMixin'
    export default {
        name: 'WebAppDashboard',
        components: {EventTable, LinkedUsersDisplay, RequestsDisplay, PopupDialog, SnackBar},
        mixins: [loadingMixin],
        data() {
            return {
                loadedAmountRequired: 1,
                dialog: false,
                snackbar: false,
                events: [],
                /* 
                linkedUsersData - zoznam kde je jeden prvok v tvare:
                {
                    name: String, (meno a priezvisko Usera)
                    events: [
                        {
                            ID_offer: Integer, (ID Eventu)
                            isActive: Boolean, (status linku daneho Usera na dany Event)
                            worked: String (kolko hodin ma odrobenych na danom evente)
                        }
                    ],
                    ID_User: Integer (ID Usera)
                }
                */
               linkedUsersData: [],
               /* 
                pendingRequests - zoznam kde je jeden prvok v tvare:
                {
                    ID_offer: Integer (ID Eventu),
                    requests: [
                        ID_User: Integer (ID Usera),
                        ID: Integer (ID linku),
                        username: String (Meno Usera),
                        decision: Boolean (default - null)
                    ]
                }
                */
               pendingRequests: []
            }
        },
        methods: {
            reloadData() {
                this.getEventData()
            },
            getEventData() {
                axios.get('/auth/oz_get_offers')
                .then(response => {
                    this.getEventContent(response.data.offers)
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            },
            getEventContent(ids) {
                axios.post('/get_offers_info', {ID_offers: ids})
                .then(response => {
                    this.events = response.data.info.filter((element) => {
                      // TODO Move isActive on a server side
                        return element.isActive
                    });

                    this.events.forEach(e => {
                      (e.tags || []).forEach((tag, indx) => {
                        if(!this.$store.state.tags[tag]){
                          const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                          const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                          e.tags[indx] = key;
                        }
                      });
                    });

                    this.getLinkedUsersData()
                    this.getPendingRequestsData()
                    this.load(1)
                })
                .catch(() => {
                    this.dialog = true
                })
            },
            getLinkedUsersData() {
                this.linkedUsersData = []
                axios.get('/auth/oz_get_links')
                .then(
                    response => {
                        response.data.links.forEach(link => {
                            let foundIndex = 0
                            let isUser = false
                            for (var i = 0; i < this.linkedUsersData.length; i++) {
                                if (this.linkedUsersData[i].ID_user == link.ID_user) {
                                    foundIndex = i
                                    isUser = true
                                    break
                                }
                            }
                            let eventObject = {ID_offer: link.ID_offer, isActive: !!link.isActive, worked: link.worked}
                            if (isUser) {
                                this.linkedUsersData[foundIndex].events.push(eventObject)
                            }
                            else {
                                this.linkedUsersData.push({
                                    name: link.name,
                                    events: [eventObject],
                                    ID_user: link.ID_user
                                })
                            }
                        })
                    }
                )
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            },
            getPendingRequestsData() {
                this.pendingRequests = []
                axios.get('/auth/get_pending_requests')
                .then(
                    response => {
                        response.data.requests.forEach(request => {
                            axios.post('/auth/user', {ID_user: request.ID_user})
                            .then(response => {
                                let foundIndex = 0
                                let isOffer = false
                                for (var i = 0; i < this.pendingRequests.length; i++) {
                                    if (this.pendingRequests[i].ID_offer == request.ID_offer) {
                                        foundIndex = i
                                        isOffer = true
                                        break
                                    }
                                }
                                if (isOffer) {
                                    this.pendingRequests[foundIndex].requests.push({ID_user: request.ID_user, tags: response.data.tags, desc: response.data.desc,  ID: request.ID, username: request.username})
                                }
                                else {
                                    this.pendingRequests.push({
                                        ID_offer: request.ID_offer,
                                        requests: [{ID_user: request.ID_user, tags: response.data.tags, desc: response.data.desc,  ID: request.ID, username: request.username}]
                                    })
                                }
                            })
                        })
                    }
                ).catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            }
        },
        computed: {
            eventsOrderedByDate: function () {
                let orderedEvents = []
                if (this.$store.state.pastEvents) {
                    orderedEvents = this.events
                }
                else {
                    orderedEvents = [...this.events.filter((event) => {
                        if (event.type == 0) {
                            return new Date(`${event.endDate}T${event.endTime}:00`) >= Date.now()
                        } else if (event.type == 1) {
                            return new Date(`${event.endDate}T24:00:00`) >= Date.now()
                        } else if (event.type == 2) {
                            return new Date(`${event.nextEventDate}T24:00:00`) >= Date.now() // should always be true
                        } else if (event.type == 3) {
                            return new Date(`${event.nextEventDate}T${event.endTime}:00`) >= Date.now() // should always be true
                        } else {
                            return true
                        }
                    })]
                }
                orderedEvents.sort((a, b) => {
                    if (a.type == 4) {
                        return 1
                    } else if (b.type == 4) {
                        return -1
                    } else {
                        return new Date(`${a.nextEventDate}T${a.nextEventTime || "00:00"}:00`).getTime() - new Date(`${b.nextEventDate}T${b.nextEventTime || "00:00"}:00`).getTime()
                    }
                })
                return orderedEvents
            }
        },
        created() {
            if (this.$store.state.loggedInFlag !== 1) {
                this.$router.push({name: 'WebAppHome'})
            }
            if (this.$route.params.created) {
                this.snackbar = true
            }
            this.unload()
            this.getEventData()
        }
    }
</script>

<style lang="scss" scoped>

</style>