<template>
    <div>
        <v-row>
            <v-col cols=12 md=6 align-self="center">
                <div class="headline">{{ titleComponent || $t("linkedUsers.title") }}</div>
            </v-col>
            <v-col cols=12 md=4 lg=3 v-if="titleFilter">
                <v-select
                dark
                background-color="rgb(255,157,113)"
                color="white"
                rounded
                filled
                dense
                :items="selectItems"
                item-text="title"
                item-value="ID_offer"
                :label="$t('linkedUsers.chooseOffer')"
                v-model="eventID"
                ></v-select>
            </v-col>
        </v-row>
        <v-row v-if="filteredUsers.length" wrap>
            <v-col cols="12" sm="6" md="4" lg="4" v-for="(link, index) in filteredUsers" :key="index">
                <UserEventLink
                    :user="link"
                    :onlyActive="!isActiveCheckBox"
                />
            </v-col>
        </v-row>
        <div v-else>
            {{ $t("linkedUsers.noUsers") }}
        </div>
        <!--<v-btn @click="downloadPDF">Stiahnuť údaje</v-btn>-->
    </div>
</template>

<script>
    import pdfMake from "pdfmake/build/pdfmake"
    import pdfFonts from "pdfmake/build/vfs_fonts";
    import axios from 'axios'
    import UserEventLink from '@/components/events/UserEventLink'
    export default {
        name: "LinkedUsersDisplay",
        components: {UserEventLink},
        props: {
            users: {
                type: Array,
                required: true,
            },
            /*
            users - zoznam kde je jeden prvok v tvare:
            {
                name: String, (meno a priezvisko Usera)
                events: [
                    {
                        ID_offer: Integer, (ID Eventu)
                        isActive: Boolean, (status linku daneho Usera na dany Event)
                        worked: String (kolko hodin ma odrobenych na danom evente)
                    }
                ],
                ID_User: Integer (ID Usera)
            }
            */
            eventsOz: {
                type: Array,
                required: false
            },
            /*
            eventsOz - zoznam eventov danej Ozky, prvok zoznamu musi obsahovat:
            {
                title,
                ID_offer
            }
             - povinne v pripade ze komponent obsahuje titleFilter
            */
            titleFilter: {
                type: Boolean,
                default: false
            },
            isActiveFilter: {
                type: Boolean,
                default: false
            },
            titleComponent: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                eventID: -1,
                isActiveCheckBox: false,
                events: this.eventsOz,
            }
        },
        watch: {
            eventsOz: function() {
                this.events = this.eventsOz
                this.eventID = -1
            }
        },
        computed: {
            selectItems () {
                let eventsLocal = Array.from(this.events)
                eventsLocal.unshift({title: this.$t("linkedUsers.all"), ID_offer: -1})
                return eventsLocal
            },
            filteredUsers() {
                let filteredUsers = []
                if (this.titleFilter || this.isActiveFilter) {
                    for (let i = 0; i < this.users.length; i++) {
                        for (let j = 0; j < this.users[i].events.length; j++) {
                            let addUser = true
                            if (this.titleFilter) {
                                let ID_offerSelect = this.eventID
                                if (ID_offerSelect == -1 || ID_offerSelect == this.users[i].events[j].ID_offer) {
                                    addUser = true
                                }
                                else {
                                    addUser = false
                                }
                            }
                            if (this.isActiveFilter) {
                                if (!this.isActiveCheckBox) {
                                    if (this.users[i].events[j].isActive && addUser) {
                                        addUser = true
                                    }
                                    else {
                                        addUser = false
                                    }
                                }
                            }
                            else {
                                if (this.users[i].events[j].isActive && addUser) {
                                    addUser = true
                                }
                                else {
                                    addUser = false
                                }
                            }
                            if (addUser) {
                                filteredUsers.push(this.users[i])
                                break
                            }
                        }
                    }
                }
                else {
                    filteredUsers = this.users
                }
                return filteredUsers
            }
        },
        methods: {
            async downloadPDF() {
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                var dd = {
                    content: [
                        {text: 'Dobrovolnici', style: 'header'}
                    ],
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 0, 10]
                        },
                        table: {
                            margin: [0, 5, 0, 15]
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 13
                        }
                    }
                }
                var table = {
                    style: 'tableExample',
                    table: {
                        widths: [200, 200],
                        body: [
                            [{text: 'Meno', style: 'tableHeader'}, {text: 'Email', style: 'tableHeader'}]
                        ]
                    }
                }
                await this.asyncForEach(this.filteredUsers, async element => {
                    var result = await axios.post('/auth/user', {ID_user: element.ID_user})
                    if (result.status == 200) {
                        table.table.body.push([result.data.name, result.data.email])
                    }
                });
                dd.content.push(table)
                pdfMake.createPdf(dd).download()
            },
            async asyncForEach(array, callback) {
                for (let index = 0; index < array.length; index++) {
                    await callback(array[index], index, array);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
