export default {
    data() {
        return {
            loadedAmount: 0,
            loadedAmountRequired: 0,
            loadingContent: true
        }
    },
    methods: {
        load(amount) {
            this.loadedAmount += amount
            if (this.loadedAmount >= this.loadedAmountRequired) {
                this.loadingContent = false
            }
        },
        unload() {
            this.loadedAmount = 0
            this.loadingContent = true
        }
    }
}